@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradient-orange {
    background: linear-gradient(282deg, #FF6654 0%, #FDA463 100%);
}

.btn-orange {
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    background: #FF6654;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
}

.btn-orange:hover {
    background-color: #ff6654d9;
}
.gradient-red{
    border-radius: 0px 0px 30px 30px;
    background: linear-gradient(181deg, #DC1B05 24.01%, #FA7262 99.51%);
}
.gradient-red-noround {
    background: linear-gradient(181deg, #DC1B05 24.01%, #FA7262 99.51%);
}

.card-gradient-red {
    border-radius: 10px;
    background: linear-gradient(33deg, #FF6654 17.35%, #DC1B05 105.08%);
}

.form-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: .25rem;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
}


.backdrop {
    position: fixed;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
}


 .wrapperClassName {
     border: 1px solid rgb(231, 221, 221);
     padding: 1rem;
 }
 .header {
     background: linear-gradient(rgb(255 158 119), rgba(255, 255, 255, 1));
 }
.sweet-loading{
display: flex;
margin: auto;    
    padding: 5rem;
}


